function tabs() {
	var $tabs = $('.wrap__tabs .tab');
	
	$tabs.each(function(i, ele) {
		var $this = $(this);

		if (i !== 0) {
			$this.addClass('isClosed');
		} else {
			$this.addClass('isOpen');
		}
	});

	$tabs.on('click', function(e) {
		var $this = $(this);

		if ($this.hasClass('isOpen')) {
			$this.removeClass('isOpen').addClass('isClosed');
		} else {
			$this.removeClass('isClosed').addClass('isOpen');
		}
	});
}

function slider() {
	var sliderOpts = {
		mode: 'fade',
		easing: 'easeInOutSine',
		speed: 800
	};

	$('.bxslider').bxSlider(sliderOpts);
}

function onYouTubeIframeAPIReady() {
	var $videos = $('.youtube-video'),
		len = $videos.length;

	window.players = [];

	$videos.each(function(i, ele) {
		var $this = $(this),
				$placeholder = $this.append('<div></div>').find('div');
		window.players.push(new YT.Player($placeholder[0], {
			width : '560',
			height : '315',
			videoId: $this.data('yt-id'),
			playerVars: {
				rel: 0,
				html5: 1,
				wmode: 'opaque'
			},
		}));
	});
}

function videoSlider() {
	// create nav wrapper
	var $videoList = $('.list__videos');
	$videoList.after('<nav class="nav__video_list"><ul></ul></nav>');

	//  set first video to is active and create nav
	$videoList.find('li').each(function(i, ele) {
		var $this = $(ele),
				navItem = '<li><a href="javascript:;" data-video-btn="'+ i +'">'+
										$this.data('title') + 
										'</a></li>';

		if (i === 0) {
			$this.addClass('is--active');
		}

		$this.attr('data-video', i);

		$('.nav__video_list ul').append(navItem);
	});

	// Attach the event listener for the video buttons
	$('.nav__video_list').on('click', 'a', function(e) {
		var videoId = $(this).data('video-btn');

		e.preventDefault();

		// loop through videos and add active to the correct video
		// remove from the rest
		$videoList.find('li').each(function(i, ele) {
			var $this = $(ele);

			if($this.data('video') === videoId) {
				$this.addClass('is--active');
			} else {
				$this.removeClass('is--active');
			}
		});
		
		for (var i in window.players) {
			var player = window.players[i]; 

			console.log(player);

			if (player.getPlayerState() === 1) {
				// if playing, pause
				player.pauseVideo();
			} else if(player.getPlayerState() === 2) {
				// if paused do nothing
			} else {
				// everything else, send to unstarted
				player.stopVideo(-1);
			}
		}
	});
}

$(document).ready(function() {
	$('html').removeClass('no-js').addClass('js');
	
	slider();
	videoSlider();
	tabs();
});